export default {
    data() {
        return {}
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        // tipsShow: {
        //     get() {
        //         return this.show
        //     },
        //     set(val) {
        //         this.$emit('update:show', val)
        //     },
        // }
    },
    filters: {},
    methods: {
        toEditEvectionInfo() {
            this.$emit('edit')
        }
    }
}